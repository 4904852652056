require('../../node_modules/jqueryui/jquery-ui')

module.exports = {
  init: () => {
    function initAccordion () {
      if (isInitialized() == false) {
        $('#footer-accordion')
          .accordion({
            collapsible: true,
            heightStyle: 'content',
            active: false,
            header: '.footer-title'
          })
          .show()
      }
    }
    function destroyAccordion () {
      if (isInitialized() == true) {
        $('#footer-accordion').accordion('destroy')
      }
    }
    function isTabletWidth () {
      return $('.mediaquery--tablet').is(':visible')
    }
    function isInitialized () {
      return $('#footer-accordion').hasClass('ui-accordion')
    }
    function showHideAccordion () {
      if (isTabletWidth()) {
        initAccordion()
      } else {
        destroyAccordion()
      }
    }
    $(window).on('resize', function () {
      showHideAccordion()
    })
    showHideAccordion()
  }
}
