require('../../node_modules/select2/dist/js/select2.min')

module.exports = {
  init: () => {
    $('.formselect').select2({
      width: 'element',
      placeholder: 'Select a state',
      theme: 'classic'
    })
    $('.formselect').on('select2:opening select2:closing', function (event) {
      var $searchfield = $(this)
        .parent()
        .find('.select2-search__field')
      $searchfield.prop('disabled', true)
    })
  }
}
