const $ = require('../../node_modules/jquery/dist/jquery.min')

module.exports = {
  init: () => {
    const burger = $('.header-burger')
    const mobileMenu = $('.mobilemenu')

    const openMobileMenu = () => {
      const isActive = mobileMenu.hasClass('mobilemenu--active')

      if (isActive) {
        burger.removeClass('header-burger--active')
        mobileMenu.removeClass('mobilemenu--active')
      } else {
        burger.addClass('header-burger--active')
        mobileMenu.addClass('mobilemenu--active')
      }

      return false
    }
    burger.click(openMobileMenu)

    /*############## Menu handling ##############*/

    const triggericon = $('.mobilemenu-list-item-icon')
    const expander = $('.mobilemenu-list-item--expandable')

    triggericon.on('click', function () {
      $(this)
        .closest(expander)
        .toggleClass('mobilemenu-list-item--expanded')
    })
  }
}
