require('./_config')

global.jQuery = require('jquery')
global.$ = jQuery

const $ = require('../../node_modules/jquery/dist/jquery.min')
const mobileMenu = require('./_mobilemenu')
const dropdown = require('./_dropdown')
const footer = require('./_footer')
const formselect = require('./_formselect')

$(() => {
  mobileMenu.init()
  dropdown.init()
  footer.init()
  formselect.init()
})
