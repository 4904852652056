const $ = require('../../node_modules/jquery/dist/jquery.min')

module.exports = {
  init: () => {
    updateDropdownPosition()

    $(window).resize(function () {
      updateDropdownPosition()
    })

    function updateDropdownPosition () {
      $('.dropdown').each(function () {
        const dropdown = $(this)

        $(dropdown).removeClass('dropdown--inverse')

        let dropdownOffset = dropdown.offset()
        let dropdownLeft = dropdownOffset.left
        let dropdownWidth = dropdown.width()
        let windowWidth = $(window).width()
        let dropdownInWindow = dropdownLeft + dropdownWidth <= windowWidth

        if (!dropdownInWindow) {
          $(dropdown).addClass('dropdown--inverse')
        }
      })
    }
  }
}
